/**
 * This module provides interfaces and enums for the frontend application.
 */

// ==================== INTERFACES ====================

/**
 * A room is the content of a house and is shown after clicking it.
 */
export interface Room {
  id: number;
  name: string;
  type: RoomType;
  content: VideoRoom | MediaRoom | MediaBigRoom | DownloadRoom | PodcastRoom | AudioRoom | DoorRoom;
}

/**
 * Houses are viewed on the map. It contains a room.
 * It can be deactivated (not clickable) or activated (clickable & visible colored).
 */
export interface House {
  id: number;
  mapLocationId: number;
  name: string;
  type: HouseType;
  entryRoom: Room | null;
  svgPath: string; // svg path of hous form (called 'd' in paths.json)
  isActive: boolean; // house is clickable
  doorPositionX?: number; // position of door on house (centered) -> transform(number, number)
  doorPositionY?: number; // position of door on house (centered) -> transform(number, number)
  color?: string; // css fill color
  className?: string; // css class
  fillRule?: 'nonzero' | 'evenodd' | 'inherit' | undefined; // css specific
}

/**
 * A room with multiple 'doors' (e.g. red-room).
 */
export interface DoorRoom {
  doors: Door[];
}

/**
 * A room displaying downloadable assets.
 */
export interface DownloadRoom {
  title: string;
  assets: Array<{
    imagePath: string;
    name?: string;
  }>;
}

/**
 * A room displaying videos.
 */
export interface VideoRoom {
  title: string;
  url: string;
  width: string;
  height: string;
  autoplay: boolean;
  description: string;
}

/**
 * A room containing a list of podcasts (shown as cards).
 */
export interface PodcastRoom {
  podcasts: Array<Podcast>;
  description: string;
}
/**
 * A room containing a list of podcasts (shown as cards).
 */
export interface AudioRoom {
  url: string;
  title?: string;
  description?: string;
  autoPlay?: boolean;
}

/**
 * A room for blogpost style content.
 */
export interface MediaRoom {
  btn: Button;
  imagePath: string;
  imageUrl?: string;
  title: string;
  type: MediaType; // In backend: externalType, influences icons (atm)
}

/**
 * A room, which uses full width (e.g. prideroom).
 */
export interface MediaBigRoom {
  btns: Button[];
  type: UiType; // In backend: uiType, influences look of btns (atm)
  imagePath?: string;
  imageUrl?: string;
  title?: string;
  stilleben?: boolean;
}

export interface Button {
  url: string;
  btnText: string;
}

export interface Podcast {
  url: string;
  title: string;
  description?: string;
  author?: string;
  category?: string;
}

/**
 * A door is part of a RoomCeption room, which includes multiple doors.
 */
export interface Door {
  id: number;
  title: string;
  url: string;
  contentList?: Array<{ title: string; info: string }>;
}

// ==================== ENUMS ====================

/**
 * Possible room types (If none applies, add a new one here + a new export interface)
 */
export enum RoomType {
  DOOR_ROOM = 'roomception',
  DOWNLOADABLE = 'downloadables',
  VIDEO = 'video',
  PODCAST = 'podcast',
  AUDIO = 'audio',
  MEDIA = 'media',
  MEDIA_BIG = 'media-big',
}

/**
 * Possible house types. Can later e.g. be used for grouping.
 * Influences the texture on the houses.
 */
export enum HouseType {
  PRIDE = 'pride',
  NORMAL = 'normal',
  SYNCHRONICITY = 'synchronicity',
  RED_ROOM = 'red-room',
  NEIGHBOURLIGHT = 'neighbourlight',
  DEACTIVATED = 'deactivated',
  GREEN = 'green', // Podcast & Spotify house
}

/**
 * Possible media types. Influences the look of the icon-btn.
 */
export enum MediaType {
  MEDIUM = 'medium',
  SLACK = 'slack',
  DROPBOX = 'dropbox',
  OTHER = 'other',
}

export enum UiType {
  PRIDE = 'pride',
  NORMAL = 'normal',
}
