import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import queryString from 'query-string';
import classes from './AuthCallback.module.scss';

import { login } from 'services/authenticationService/AuthenticationService';

export const AuthCallback: React.FunctionComponent = () => {
  const parsedParameters = queryString.parse(window.location.search);
  const authorizationCode = parsedParameters.code ? parsedParameters.code : '';
  const [error, setError] = useState('');

  useEffect(() => {
    const onError = (error: { message: string }) => setError(error.message || 'unknown');

    const loginUser = async () => {
      await login(authorizationCode.toString());
      window.location.replace('/');
    };
    try {
      loginUser();
    } catch (err) {
      onError(err);
    }
  });

  return (
    <div data-cy="load-container" className={classes.loadContainer}>
      {!error ? (
        <>
          <CircularProgress size="78px" />
          <div>Logging in...</div>
        </>
      ) : (
        <>
          <div>Error while logging in.</div>
          <Link to="/login">Retry</Link>
          <div>Error message: {error}</div>
        </>
      )}
    </div>
  );
};
