import React, { useState, useContext, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import synchronicityShowBg from '../../assets/svg/star-pattern.svg';
import prideRainbow from '../../assets/svg/rainbow-02.svg';
import spotify from '../../assets/img/spotify.png';
import rebirth from '../../assets/img/rebirth.png';
import pastfuture from '../../assets/img/pastfuture.png';
import evolution from '../../assets/img/evolution.png';
import paths from '../../assets/paths.json';
import House from '../house/House';
import Legend from '../legend/Legend';
import DoorOverlay from '../doorOverlay/DoorOverlay';
import * as Synchronicity from 'synchronicity-types';
import { PrivateStateContext } from 'context/PrivateContext';
import * as d3 from 'd3';
import CountdownVideo from 'components/countdownVideo/CountdownVideo';

export const Map: React.FunctionComponent = () => {
  const { contentList } = useContext(PrivateStateContext);
  const [legendText, setLegendText] = useState<string | undefined>(undefined);
  const [legendPosition, setLegendPosition] = useState<{ posX: number; posY: number }>({ posX: 0, posY: 0 });
  const [{ x, y, k }, setTransform] = useState({ x: 0, y: 0, k: 1 });
  const [enteringHouse, setEnteringHouse] = useState<boolean>(false);
  const [houseName, setHouseName] = useState<string>('');
  const [houseType, setHouseType] = useState<Synchronicity.HouseType>(Synchronicity.HouseType.NORMAL);

  const onMouseEnterHouse = (houseId: number) => {
    const houseContent = contentList.filter(content => content.mapLocationId === houseId);
    setLegendText(houseContent[0]?.name);
  };

  const onMouseLeaveHouse = () => {
    setLegendText(undefined);
  };

  const handleOnMouseMove = (event: React.MouseEvent) => {
    if (legendText) {
      const posX = event.pageX + 10;
      const posY = event.pageY + 20;
      setLegendPosition({ posX, posY });
    }
  };

  const map = useRef(null);

  //Reload potentially stored scroll position
  useEffect(() => {
    // Get previous coordinates if stored
    const storeString = window.sessionStorage.getItem('stored_pos');
    window.sessionStorage.removeItem('stored_pos');
    let savedPos = { x: 230, y: -150, k: 0.75 }; //Default scroll target
    if (storeString !== null) {
      try {
        savedPos = JSON.parse(storeString);
      } catch (error) {
        //Could not parse json, the default target remains
      }
    }

    // Tweak zooming behaviour: drag to pan, increase how much you can zoom out, etc
    const svg = d3.select(map.current);
    const zoom = d3
      .zoom()
      .translateExtent([
        [-1200, -200],
        [3600, 4600],
      ])
      .scaleExtent([0.5, 4])
      .on('zoom', event => {
        const zoomState = event.transform;
        setTransform(zoomState);
      });
    svg.call(zoom).call(zoom.transform, d3.zoomIdentity.translate(savedPos.x, savedPos.y).scale(savedPos.k));
  }, []);

  const history = useHistory();

  const doorAnimationLengthInMs = 900;
  const delayBeforeDoorAnimationInMs = 100;

  const handleHouseClick = (houseId: number) => {
    const house = contentList.filter(content => content.mapLocationId === houseId)[0];
    if (!house) {
      return;
    }

    // Save d3 coordinates when entering house
    const scrollPos = { k: k, x: x, y: y };
    const storeString = JSON.stringify(scrollPos);
    window.sessionStorage.setItem('stored_pos', storeString);

    setHouseName(house?.name);
    setHouseType(house?.type);
    setEnteringHouse(true);

    setTimeout(() => {
      history.push(`/house/${houseId}`);
    }, doorAnimationLengthInMs + delayBeforeDoorAnimationInMs);
  };

  // Only houses from the ContentList are clickable
  const houses = paths.map(data => (
    <House
      key={data.id}
      transform={data.transform}
      d={data.d}
      fill={data.fill}
      id={data.id}
      className={data.className}
      doorTransform={data.doorTransform}
      onMouseEnter={onMouseEnterHouse}
      onMouseLeave={onMouseLeaveHouse}
      onClick={() => handleHouseClick(data.id)}
    />
  ));

  return (
    <div>
      <CountdownVideo />
      <div onMouseMove={handleOnMouseMove} style={{ maxHeight: '100vh', overflow: 'hidden' }}>
        <Legend text={legendText} posX={legendPosition.posX} posY={legendPosition.posY} />
        {enteringHouse && (
          <DoorOverlay
            houseName={houseName}
            houseType={houseType}
            animationLengthInMs={doorAnimationLengthInMs}
            delayBeforeAnimationInMs={delayBeforeDoorAnimationInMs}
          />
        )}
        {/* TODO: move to component and set props accordingly */}
        {/* The graphics is completely an svg - every section describes a section for new content,
              the overlayed icon is provided at the end, copy and paste it when a new section becomes available */}
        <svg width="100%" viewBox="0 0 2247 2310" version="1.1}" ref={map} style={{ minHeight: '100vh' }}>
          <g transform={`translate(${x}, ${y}) scale(${k})`}>
            {/* In defs, filters and patterns are defined to style the svg with textures etc.*/}
            <defs>
              {/* Synchronicity show hover texture */}
              <pattern id="synchronicityShow" patternUnits="userSpaceOnUse" width="100" height="100">
                <image href={synchronicityShowBg} width="100" height="100" />
              </pattern>

              {/* Pride house 279 texture */}
              <pattern id="prideRainbow-house-279" y="-.05" width="100%" height="120%">
                <image href={prideRainbow} width="6%" />
              </pattern>

              {/* Pride house 269 texture */}
              <pattern id="prideRainbow-house-269" y=".47" width="100%" height="145%" patternTransform="rotate(45 0 0)">
                <image href={prideRainbow} width="13%" />
              </pattern>

              {/* Spotify image texture */}
              <pattern id="spotify" width="100%" height="100%">
                <image href={spotify} width="3%" />
              </pattern>

              {/* The Rebirth image texture */}
              <pattern id="rebirth" width="100%" height="100%">
                <image href={rebirth} width="4%" />
              </pattern>

              {/* Past & Future image texture */}
              <pattern id="pastfuture" width="100%" height="100%">
                <image href={pastfuture} width="3%" />
              </pattern>

              {/* End of Evolution image texture */}
              <pattern id="evolution" width="100%" height="100%">
                <image href={evolution} width="3%" />
              </pattern>
            </defs>

            {houses}
          </g>
        </svg>
      </div>
    </div>
  );
};

export default Map;
