// @flow
import React, { ReactElement, useContext } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import classes from './Page.module.scss';
import Modal from 'components/modal/Modal';
import Video from 'components/roomTypes/Video';
import Podcast from 'components/roomTypes/Podcast';
import Media from 'components/roomTypes/Media';
import Audio from 'components/roomTypes/Audio';
import Downloadables from 'components/roomTypes/downloadables/Downloadables';
import MediaBig from 'components/roomTypes/MediaBig';
import RoomCeption from 'components/roomTypes/doorRoom/DoorRoom';
import * as Synchronicity from 'synchronicity-types';
import { PrivateStateContext } from 'context/PrivateContext';

export const Page = () => {
  const history = useHistory();
  const { id } = useParams();

  const state = useContext(PrivateStateContext);
  const room: any = state.contentList.filter(item => item.mapLocationId === +id)[0].room;

  // Get room from clicked house from content list by router id

  let title: ReactElement | undefined = <div className={classes.title}>{room.name}</div>;
  let classesList = classes.content;
  let specialType;

  // Create rendered content according to room type and apply specific styles
  const roomRendered = (() => {
    switch (room.type) {
      case Synchronicity.RoomType.DOOR_ROOM:
        return <RoomCeption doors={room.content.doors} />;
      case Synchronicity.RoomType.DOWNLOADABLE:
        classesList = classes.contentRow;
        return <Downloadables title={room.content.title} assets={room.content.assets} />;
      case Synchronicity.RoomType.VIDEO:
        title = <h2 className={classes.titleVideo}>{room.content.title}</h2>;
        return (
          <Video
            title={room.content.title}
            url={room.content.url}
            width={room.content.width}
            height={room.content.height}
            autoplay={room.content.autoplay}
            description={room.content.description}
          />
        );
      case Synchronicity.RoomType.PODCAST:
        specialType = 'podcast';
        title = <h2 className={classes.titlePodcast}>{room.content.name}</h2>;
        return <Podcast podcasts={room.content.podcasts} description={room.content.description} />;
      case Synchronicity.RoomType.AUDIO:
        return (
          <Audio
            url={room.content.link}
            title={room.content.title}
            description={room.content.description}
            autoPlay={room.content.autoPlay}
          />
        );
      case Synchronicity.RoomType.MEDIA:
        title = undefined;
        return (
          <Media
            btn={room.content.btn}
            imagePath={room.content.imagePath}
            title={room.content.title}
            type={room.content.type}
          />
        );
      case Synchronicity.RoomType.MEDIA_BIG:
        specialType = room.content.type;
        classesList = classes.fullWidthContent;
        return (
          <MediaBig
            btns={room.content.btns}
            type={room.content.type}
            imagePath={room.content.imagePath}
            imageUrl={room.content.imageUrl}
            title={room.content.title}
            stilleben={room.content.stilleben}
          />
        );
      default:
        return <></>;
    }
  })();

  return (
    <>
      <Modal
        onClick={() => {
          history.push('/');
        }}
        type={specialType}
      >
        <div className={classes.content}>
          {title}
          <div className={classesList}>{roomRendered}</div>
        </div>
      </Modal>
    </>
  );
};

export default Page;
