import React from 'react';
import classes from './Legend.module.scss';

interface LegendProps {
  text: string | undefined;
  posX: number;
  posY: number;
}

const Legend: React.FC<LegendProps> = ({ text, posX, posY }) => {
  return text && posX && posY ? (
    <div style={{ left: posX, top: posY }} className={classes.legend}>
      {text.toUpperCase()}
    </div>
  ) : null;
};

export default Legend;
