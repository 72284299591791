/**
 * Room content component, which fills the whole modal.
 * Can have multiple buttons and a background image.
 */
import React from 'react';
import Button from 'components/button/Button';
import classes from '../page/Page.module.scss';
import * as Synchronicity from 'synchronicity-types';
import 'react-image-gallery/styles/scss/image-gallery.scss';
import { Stilleben } from './_Stilleben';

const MediumBig: React.FunctionComponent<Synchronicity.MediaBigRoom> = ({
  btns,
  type,
  imagePath,
  imageUrl,
  title,
  stilleben,
}) => {
  const btnClass = type === Synchronicity.UiType.PRIDE ? classes.prideButton : classes.button;
  const btnsRendered = btns.map((btn, index) => (
    <div className={btnClass} key={index}>
      <Button buttonText={btn.btnText} link={btn.url} prideButton={type === Synchronicity.UiType.PRIDE} />
    </div>
  ));

  const getImg = () => {
    return <img src={imagePath ? imagePath : ''} alt={title ? title : 'image'} />;
  };
  const img = getImg();

  const defaultContent = () => {
    return (
      <>
        <div className={classes.fullWidthHeader}>
          {imageUrl ? (
            <a href={imageUrl} target="_blank" rel="noopener noreferrer">
              {img}
            </a>
          ) : (
            <>{img}</>
          )}
        </div>
        <h1 className={classes.fullWidthCenteredHeading}>{title ? title : ''}</h1>
        {btnsRendered}
      </>
    );
  };

  return stilleben ? <Stilleben /> : defaultContent();
};

export default MediumBig;
