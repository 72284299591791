import React from 'react';
import classes from './Button.module.scss';

interface ButtonProps {
  mainButton?: boolean;
  buttonText: string;
  link: string;
  prideButton?: boolean;
}

const Button: React.FunctionComponent<ButtonProps> = ({
  prideButton = false,
  mainButton = false,
  buttonText,
  link,
}) => {
  const buttonClass = mainButton ? classes.mainButton : prideButton ? classes.pride : classes.button;
  const makeNewTab = link.includes('localhost') ? '_self' : '_blank';
  // const makeNewTab = link.includes('synchronicity.netlight.com') ? '_blank' : '_self';
  return (
    <a href={link} target={makeNewTab} rel="noopener noreferrer" className={buttonClass}>
      <div className={classes.buttonText}>{buttonText}</div>
    </a>
  );
};

export default Button;
