/**
 * Room component, which displayes a list of downloadable assets.
 */
import React from 'react';
import classes from './Downloadables.module.scss';
import * as Synchronicity from 'synchronicity-types';

const Downloadables: React.FunctionComponent<Synchronicity.DownloadRoom> = ({ title, assets }) => {
  const assetPaths = assets.map(el => el.imagePath);

  const assetComponents = [];
  for (let i = 0; i < assetPaths.length; i++) {
    assetComponents.push(
      <a href={assetPaths[i]} key={i} download>
        <img className={classes.image} src={assetPaths[i]} alt="Downloadable Content"></img>
      </a>
    );
  }

  return <div className={classes.assetContainer}>{assetComponents}</div>;
};

export default Downloadables;
