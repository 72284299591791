import React, { useState, useEffect } from 'react';
import classes from './DoorOverlay.module.scss';
import * as Synchronicity from 'synchronicity-types';

const DoorOverlay: React.FC<{
  houseName?: string;
  houseType?: Synchronicity.HouseType;
  animationLengthInMs?: number;
  delayBeforeAnimationInMs?: number;
}> = ({
  houseName = '',
  houseType = Synchronicity.HouseType.NORMAL,
  animationLengthInMs = 900,
  delayBeforeAnimationInMs = 100,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsOpen(true);
    }, delayBeforeAnimationInMs);
  }, [delayBeforeAnimationInMs]);

  const color = (() => {
    switch (houseType) {
      case Synchronicity.HouseType.RED_ROOM:
        return classes.red;
      case Synchronicity.HouseType.GREEN:
        return classes.green;
      case Synchronicity.HouseType.NORMAL:
      default:
        return classes.purple;
    }
  })();

  const doorAnimation = (
    <div className={isOpen ? classes.openDoor : classes.closedDoor}>
      <div className={classes.doorway}>
        <div
          style={{ backgroundColor: color, transition: `transform ${animationLengthInMs}ms` }}
          className={classes.door}
        />
      </div>
    </div>
  );

  return (
    <div className={classes.darkOverlay}>
      {doorAnimation}
      <h1 style={{ color: color }}>{houseName}</h1>
    </div>
  );
};

export default DoorOverlay;
