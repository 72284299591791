import React, { useState } from 'react';
import classes from '../page/Page.module.scss';
import * as types from 'synchronicity-types';
import PodcastPlayerCard from 'components/podcastPlayerCard/PodcastPlayerCard';

const Podcast: React.FunctionComponent<types.PodcastRoom> = ({ podcasts, description }) => {
  const [isPlaying, setIsPlaying] = useState<types.Podcast>(undefined);

  const renderPlaying = () => {
    if (isPlaying) {
      return (
        <PodcastPlayerCard
          title={isPlaying.title}
          link={isPlaying.url}
          description={isPlaying.description}
          authors={isPlaying.author}
          category={isPlaying.category}
          key={isPlaying.title}
          onExpand={() => setIsPlaying(isPlaying)}
          onMinimize={() => setIsPlaying(undefined)}
          isPlaying={true}
        />
      );
    }
  };

  const renderNotPlaying = () => {
    const playing = podcasts.filter(podcast => podcast !== isPlaying);
    return playing.map(podcast => (
      <PodcastPlayerCard
        title={podcast.title}
        link={podcast.url}
        description={podcast.description}
        authors={podcast.author}
        category={podcast.category}
        key={podcast.title}
        onExpand={() => setIsPlaying(podcast)}
        onMinimize={() => setIsPlaying(undefined)}
      />
    ));
  };

  return (
    <>
      <div className={classes.PodcastContent}>
        <p>{description}</p>
        <div className={classes.Podcasts}>
          {renderPlaying()}
          {renderNotPlaying()}
        </div>
      </div>
    </>
  );
};

export default Podcast;
