import { getCookie, removeCookie, setCookie } from 'utils/CookieUtils';
import { handleErrors } from '../errorHandling/ErrorHandling';

export const SESSION_NAME = '__session';

type JwtToken = {
  token: string;
};

const fetchJwToken = async (code: string): Promise<JwtToken> => {
  const environment = process.env.REACT_APP_ENV === 'production' ? 'prod' : 'dev';
  const redirectUri = encodeURIComponent(`${window.location.origin}/oauth-callback`);
  const response = await fetch(
    `https://api.synchronicity-neighbourhood.edge-labs.com/${environment}/api/auth/oauth2/jwt-tokens?code=${code}&redirectUri=${redirectUri}`,
    {
      method: 'GET',
    }
  );
  handleErrors(response);
  return response.json();
};

export const login = async (code: string) => {
  const token = await fetchJwToken(code);
  setCookie(SESSION_NAME, token.token);
};

export const logout = () => {
  removeCookie(SESSION_NAME);
  window.location.replace('/login');
};

export const isLoggedIn = () => {
  const cookie = getCookie(SESSION_NAME);

  return cookie ? true : false;
};
