export interface Point {
  x: number;
  y: number;
}

/**
 * Interpolates the line created by point A and B, and evaluates a point t percent
 * through this line
 * @param A The first point
 * @param B The second point
 * @param t The distance from point A to point B to calculate, in range [0, 1]
 */
function lerp(A: Point, B: Point, t: number): Point {
  const x = A.x * (1 - t) + B.x * t;
  const y = A.y * (1 - t) + B.y * t;

  return { x, y };
}

/**
 * Evalues a point on the cubic bezier curve created by points A, B, C, and D
 * @param A The starting point
 * @param B The control point for the starting point
 * @param C The control point for the end point
 * @param D The end point
 * @param t The distance travelled along the cubic bezier curve
 */
function cubicBezierInterpolation(A: Point, B: Point, C: Point, D: Point, t: number) {
  const a = lerp(A, B, t);
  const b = lerp(B, C, t);
  const c = lerp(C, D, t);
  const d = lerp(a, b, t);
  const e = lerp(b, c, t);
  const f = lerp(d, e, t);

  return f;
}

/**
 * Converts a (subset) of a SVG path string into an array of points
 * @param pathString A path string that can be set as data in an SVG path string, e.g. <path d="this is the path string" />
 */
export function getPathPoints(pathString: string): Array<Point> {
  const points = [];
  // This regex extracts all regex commands used in paths.json
  // Note: this will break in case more commands are included
  const commands = pathString.split(/(?=[LMCZ])/i);

  let startX = 0;
  let startY = 0;
  let hasStarted = false;
  for (const commandData of commands) {
    const command = commandData[0];
    const data = commandData.substr(1);
    // Okay so this regex extracts floating point numbers, I'll break it down
    // -? => It can be negative, \d+ => one or more digits, (\.\d+)+ => maybe one or more decimals
    // (e-?\d+)? => maybe an exponent with E notation
    const coordinates = data.match(/-?\d+(\.\d+)?(e-?\d+)?/g)?.map(Number.parseFloat);

    if (!hasStarted) {
      if (command === 'M') {
        startX = coordinates[0];
        startY = coordinates[1];
      }
      points.push({ x: startX, y: startY });
      hasStarted = true;
    }

    if (command === 'L') {
      // Line to the coordinates
      points.push({
        x: coordinates[0],
        y: coordinates[1],
      });
    } else if (command === 'Z') {
      // Line to first point
      points.push({ x: startX, y: startY });
    } else if (command === 'C') {
      // Cubic bezier, evaluate some points
      const A = points[points.length - 1];
      const B = { x: coordinates[0], y: coordinates[1] };
      const C = { x: coordinates[2], y: coordinates[3] };
      const D = { x: coordinates[4], y: coordinates[5] };

      // Four points is a good enough appriximation of these curves
      points.push(
        cubicBezierInterpolation(A, B, C, D, 0.25),
        cubicBezierInterpolation(A, B, C, D, 0.5),
        cubicBezierInterpolation(A, B, C, D, 0.75),
        cubicBezierInterpolation(A, B, C, D, 1)
      );
    }
  }

  return points;
}

/**
 * Returns the cross product of the vectors ao and bo
 * @param a First point
 * @param b Second point
 * @param o Middle point
 */
function cross(a: Point, b: Point, o: Point) {
  return (a.x - o.x) * (b.y - o.y) - (a.y - o.y) * (b.x - o.x);
}

/**
 * Calculates and returns the convex hull (https://en.wikipedia.org/wiki/Convex_hull)
 * of the given set of points. This is an adaption of the Monotone Chain algorithm found here:
 * https://en.wikibooks.org/wiki/Algorithm_Implementation/Geometry/Convex_hull/Monotone_chain#JavaScript
 * @param points An array of points
 */
export function convexHull(points: Array<Point>): Array<Point> {
  points = points.slice().sort(function(a, b) {
    return a.x !== b.x ? a.x - b.x : a.y - b.y;
  });

  const lowerHull = [];
  for (let i = 0; i < points.length; i++) {
    while (
      lowerHull.length >= 2 &&
      cross(lowerHull[lowerHull.length - 2], lowerHull[lowerHull.length - 1], points[i]) <= 0
    ) {
      lowerHull.pop();
    }
    lowerHull.push(points[i]);
  }

  const upperHull = [];
  for (let i = points.length - 1; i >= 0; i--) {
    while (
      upperHull.length >= 2 &&
      cross(upperHull[upperHull.length - 2], upperHull[upperHull.length - 1], points[i]) <= 0
    ) {
      upperHull.pop();
    }
    upperHull.push(points[i]);
  }

  return lowerHull.concat(upperHull);
}

/**
 * Constructs a SVG path string which can be used as data for a SVG path tag, e.g.
 * <svg d="my path data" />
 * @param points An array of points
 */
export function createPath(points: Array<Point>): string {
  const moveCommand = `M${points[0].x},${points[0].y}`;
  const lineCommands = points
    .slice(1)
    .map(p => `L${p.x},${p.y}`)
    .join(' ');
  const endCommand = 'Z';

  return `${moveCommand} ${lineCommands} ${endCommand}`;
}
