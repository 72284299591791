/**
 * A room that includes multiple 'doors'.
 * A door in this case is a link to an external website.
 * This component is currently used for the 'red-room' design.
 */

import React from 'react';
import Door from 'components/door/Door';
import classes from './DoorRoom.module.scss';
import * as Synchronicity from 'synchronicity-types';

const RoomCeption: React.FunctionComponent<Synchronicity.DoorRoom> = ({ doors }) => {
  const roomList = doors.map(door => (
    <Door id={door.id} title={door.title} url={door.url} contentList={door.contentList} key={door.title} />
  ));

  return <div className={classes.Content}>{roomList}</div>;
};

export default RoomCeption;
