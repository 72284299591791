import { Close, PlayArrow } from '@material-ui/icons';
import AudioPlayer from 'components/audioPlayer/AudioPlayer';
import React, { useState } from 'react';
import classes from './PodcastPlayerCard.module.scss';

interface CardProps {
  title: string;
  description?: string;
  authors?: string;
  link: string;
  category?: string;
  onExpand?: () => void;
  onMinimize?: () => void;
  isPlaying?: boolean;
}

const PodcastPlayerCard: React.FunctionComponent<CardProps> = ({
  title,
  description,
  authors,
  link,
  category,
  onExpand,
  onMinimize,
  isPlaying = false,
}) => {
  const [expanded, setExpanded] = useState(isPlaying);
  link = link.replace('dl=0', 'dl=1');

  const getBoxContent = () => {
    if (expanded) {
      return (
        <>
          <Close
            className={classes.cross}
            onClick={e => {
              e.stopPropagation();
              onExpandOrMinimize(false);
            }}
          />
          <div className={classes.player}>
            <AudioPlayer url={link} autoPlay={true} />
          </div>
        </>
      );
    } else {
      return (
        <>
          <p>{category}</p>
          <div className={classes.CardHover}>
            <PlayArrow className={classes.hoverTitle} fontSize="large" />
          </div>
        </>
      );
    }
  };

  const onExpandOrMinimize = (expand: boolean) => {
    expand && onExpand();
    !expand && onMinimize();
    setExpanded(expand);
  };

  return (
    <div className={classes.Card + ' ' + (expanded ? classes.expanded : classes.minimized)}>
      <div className={classes.Box} onClick={() => onExpandOrMinimize(true)}>
        {getBoxContent()}
      </div>
      <div className={classes.CardInfo}>
        <h3>{title}</h3>
        <p className={classes.Description}>
          {description}
          {authors}
        </p>
      </div>
    </div>
  );
};

export default PodcastPlayerCard;
