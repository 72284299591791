/**
 * Helper component for the unique Stilleben room.
 */
import React, { useState } from 'react';
import classes from '../page/Page.module.scss';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/scss/image-gallery.scss';
import { ReactComponent as Arrow } from '../../assets/icons/left-arrow.svg';

export const Stilleben: React.FunctionComponent = () => {
  const [stillebenImgs, setStillebenImgs] = useState<object[]>(null);

  const imgAssets = names => names.map(n => ({ original: require('../../assets/img/stilleben/' + n) }));
  const boxImages = imgAssets(['boxletter1.jpg', 'boxletter2.jpg']);
  const notebookImages = imgAssets(['notebook1.jpg', 'notebook2.jpg', 'notebook3.jpg']);
  const letterImages = imgAssets(['roomletter1.jpg', 'roomletter2.jpg']);

  const slideshow = imgs => {
    return (
      <div className={classes.slideshow}>
        <div className={classes.gallery}>
          <ImageGallery
            items={imgs}
            infinite={false}
            showThumbnails={false}
            showFullscreenButton={false}
            showPlayButton={false}
            showBullets={true}
          />
        </div>
        <div onClick={() => setStillebenImgs(null)} className={classes.backButton}>
          <Arrow /> CLOSE
        </div>
      </div>
    );
  };

  const boxPath = 'M230 425 L700 455 L700 710 L265 925 L0 895 L0 468 Z';
  const notebookPath = 'M655 738 L1028 680 L1135 760 L1303 817 L1300 880 L930 925 L870 920 L655 767 Z';
  const letterPath = 'M1135 760 L1303 817 L1300 870 L1330 880 L1480 845 L1640 792 L1170 690 L1065 710 Z';

  const overlayShape = (imgs, path) => {
    return <path onClick={() => setStillebenImgs(imgs)} filter="url(#gauss)" fill="#FFF" d={path} />;
  };

  const stillebenView = () => {
    return (
      <div className={classes.overlayWrap}>
        <img src={require('../../assets/img/stilleben/stilleben.jpg')} alt="" />
        <svg viewBox="0 0 1650 1650">
          <defs>
            <filter id="gauss" x="0" y="0">
              <feGaussianBlur in="SourceGraphic" stdDeviation="3" />
            </filter>
          </defs>
          {overlayShape(boxImages, boxPath)}
          {overlayShape(notebookImages, notebookPath)}
          {overlayShape(letterImages, letterPath)}
        </svg>
      </div>
    );
  };

  return stillebenImgs ? slideshow(stillebenImgs) : stillebenView();
};

export default Stilleben;
