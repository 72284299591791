import React from 'react';
import './Login.module.scss';
import classes from './Login.module.scss';
import logo from 'assets/img/netlight-logo.png';

interface LoginProps {
  title: string;
  description: string;
  link: string;
}

const Login: React.FunctionComponent<LoginProps> = ({ title, description, link }) => {
  return (
    <div className={classes.loginScreen}>
      <div className={classes.loginContainer}>
        <div className={classes.loginFormContainer}>
          <div className={classes.loginRow}>
            <img className={classes.logo} alt="Netlight logo" src={logo} />
          </div>
          <div className={classes.loginRow}>
            <div className={classes.loginHeading}>{title}</div>
          </div>
          <div className={classes.loginRow}>
            <div className={classes.loginSubHeading}>{description}</div>
          </div>
          <div className={classes.loginRow}>
            <a href={link}>
              <img
                alt="sign in with slack"
                height="40"
                width="172"
                src="https://platform.slack-edge.com/img/sign_in_with_slack.png"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
