import React from 'react';
import SynchronicityContainer from '../synchronicityContainer/SynchronicityContainer';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { PrivateContext } from 'context/PrivateContext';

import Page from 'components/page/Page';
import { LoginContainer } from 'containers/loginContainer/LoginContainer';
import { AuthCallback } from 'containers/authCallback/AuthCallback';
import { isLoggedIn } from 'services/authenticationService/AuthenticationService';

const App: React.FunctionComponent = () => {
  return (
    <Router>
      <Switch>
        <Route path="/oauth-callback" component={AuthCallback} />
        <Route path="/login">{!isLoggedIn() ? <LoginContainer /> : <Redirect to="/" />}</Route>
        <Switch>
          {!isLoggedIn() ? (
            <Redirect to="/login" />
          ) : (
            <PrivateContext>
              <Route path="/house/:id" component={Page}></Route>
              <Route exact path="/" component={SynchronicityContainer}></Route>
            </PrivateContext>
          )}
        </Switch>
      </Switch>
    </Router>
  );
};

export default App;
