import React, { useEffect, useState } from 'react';
import * as Synchronicity from 'synchronicity-types';
import { getContentList } from 'services/pageContent/PageContent';
import { SESSION_NAME } from 'services/authenticationService/AuthenticationService';
import { getCookie } from 'utils/CookieUtils';
import { CircularProgress } from '@material-ui/core';
import classes from './PrivateContext.module.scss';

type ContentList = {
  id: number;
  mapLocationId: number;
  name: string;
  type: Synchronicity.HouseType;
  room: {
    id: number;
    name: string;
    type: Synchronicity.RoomType;
    content: Synchronicity.DoorRoom;
  };
}[];

type ContextProps = {
  contentList: ContentList;
  loading: boolean;
};

const PrivateStateContext = React.createContext<Partial<ContextProps>>({ loading: true, contentList: [] });

const PrivateContext = (props: { children: React.ReactNode }) => {
  const [state, setState] = useState({ loading: true, contentList: [] });

  useEffect(() => {
    async function fetchContent() {
      const key = SESSION_NAME;
      const token = getCookie(key);
      const content = await getContentList(token);
      setState({ loading: false, contentList: content });
    }
    fetchContent();
  }, []);

  return (
    <PrivateStateContext.Provider value={state}>
      {state.loading ? (
        <div className={classes.spinnerWrapper}>
          <CircularProgress size="78px" className={classes.spinner} />
          <div>Fetching data...</div>
        </div>
      ) : (
        props.children
      )}
    </PrivateStateContext.Provider>
  );
};

export { PrivateContext, PrivateStateContext };
