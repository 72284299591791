/**
 * Loads main view container, the map (= heart with houses).
 */
import React from 'react';
import './SynchronicityContainer.module.scss';
import { RouteComponentProps } from 'react-router-dom';
import Map from 'components/map/Map';

interface SynchronicityContainer extends RouteComponentProps {}

export const SynchronicityContainer: React.FunctionComponent = () => {
  return <Map />;
};

export default SynchronicityContainer;
