import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import Login from 'components/login/Login';

interface LoginContainer extends RouteComponentProps {}

export const LoginContainer: React.FunctionComponent = () => {
  // Use the useState-hook for managing state
  const redirectUri = encodeURIComponent(`${window.location.origin}/oauth-callback`);
  const slackLink = `https://slack.com/oauth/v2/authorize?user_scope=identity.basic&client_id=2172920488.1319569401492&redirect_uri=${redirectUri}`;

  return (
    <div className="LoginContainer">
      <Login
        title="Synchronicity"
        description="Discover events and initiatives all across the Netlight universe."
        link={slackLink}
      />
    </div>
  );
};

export default LoginContainer;
