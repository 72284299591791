/**
 * This 'door' component returns a rendered box with a door image + title & optionally content-list.
 * The content-list includes elements, consisting of a title and extra information.
 */
import React from 'react';
import classes from './Door.module.scss';
import * as Synchronicity from 'synchronicity-types';
import { ReactComponent as DoorIcon } from '../../assets/icons/room-door.svg';

const Door: React.FunctionComponent<Synchronicity.Door> = ({ title, url, contentList }) => {
  // Creates rendered content-list from param 'contentList'
  const renderedContent = contentList ? (
    contentList.map((contentElement, index) => {
      return (
        <div key={index}>
          <p className={classes.Title}>{contentElement.title}</p>
          <p className={classes.Info}>{contentElement.info}</p>
        </div>
      );
    })
  ) : (
    <></>
  );

  // Returns rendered door elements
  return (
    <div className={classes.Door}>
      <div>
        <a className={classes.Box} href={url} target="_blank" rel="noopener noreferrer">
          <DoorIcon className={classes.Icon} />
          <p>{title.toUpperCase()}</p>
        </a>
      </div>
      <ul className={classes.ContentList}>{renderedContent}</ul>
    </div>
  );
};

export default Door;
