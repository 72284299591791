import cookies from 'js-cookie';

export const setCookie = (key: string, value: string) => {
  cookies.set(key, value);
};

export const getCookie = (key: string): string | undefined => {
  return cookies.get(key);
};

export const removeCookie = (key: string) => {
  cookies.remove(key);
};
