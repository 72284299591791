/**
 * Room content components, which looks like a blogpost.
 * Contains an image (with title) and a button.
 */
import React from 'react';
import Button from 'components/button/Button';
import classes from '../page/Page.module.scss';
import * as Synchronicity from 'synchronicity-types';
import { ReactComponent as SlackIcon } from '../../assets/icons/slack-new-logo.svg';
import { ReactComponent as MediumIcon } from '../../assets/icons/medium-icon.svg';

const Medium: React.FunctionComponent<Synchronicity.MediaRoom> = ({
  btn,
  imagePath,
  imageUrl = btn.url,
  title,
  type,
}) => {
  const icon = (() => {
    switch (type) {
      case Synchronicity.MediaType.DROPBOX:
        return;
      case Synchronicity.MediaType.MEDIUM:
        return <MediumIcon />;
      case Synchronicity.MediaType.SLACK:
        return <SlackIcon />;
      case Synchronicity.MediaType.OTHER:
        return;
      default:
        return;
    }
  })();
  return (
    <>
      <div className={classes.title}>
        <div className={classes.icon}>{icon}</div>
        {title}
      </div>
      <a href={imageUrl} target="_blank" rel="noopener noreferrer">
        <img className={classes.liveImage} src={imagePath} alt={title}></img>
      </a>
      <div className={classes.button}>
        <Button buttonText={btn.btnText} link={btn.url} />
      </div>
    </>
  );
};

export default Medium;
