/**
 * This 'door' component returns a rendered box with a door image + title & optionally content-list.
 * The content-list includes elements, consisting of a title and extra information.
 */
import React, { useState, useContext } from 'react';
import classes from './CountdownVideo.module.scss';
import Countdown from 'react-countdown';
import { ReactComponent as X } from '../../assets/icons/x.svg';
import { PrivateStateContext } from 'context/PrivateContext';

const CountdownVideo: React.FunctionComponent = () => {
  const countDownTo = new Date('2021').getTime();
  const defaultPlayVideo = window.sessionStorage.getItem('autoplayCountdown');
  const [playVideo, setPlayVideo] = useState<boolean>(defaultPlayVideo !== 'false');
  const [is2021, setIs2021] = useState<boolean>(Date.now() > countDownTo);

  const { contentList } = useContext(PrivateStateContext);
  const houseContent = contentList.filter(content => content.mapLocationId === 197);

  const beforeVideo = 'https://player.vimeo.com/video/491571129?background=1';
  const afterVideo = houseContent[0].room.content['url'] + '?background=1';

  const countdownCompleteCallback = () => {
    setIs2021(true);
  };

  const disableAutoplay = () => {
    setPlayVideo(false);
    window.sessionStorage.setItem('autoplayCountdown', String(false));
  };

  const newYearVideo = () => {
    return (
      <div className={classes.fullscreenVideo}>
        <div onClick={() => setPlayVideo(false)} className={classes.closeButton}>
          <X />
        </div>
        <iframe
          src={afterVideo}
          title="Happy new year!"
          frameBorder="0"
          style={{ width: '100%', height: '100%' }}
          allow="autoplay; fullscreen; encrypted-media"
        />
      </div>
    );
  };

  const countdownVideo = () => {
    return (
      <div className={classes.countdownVideo}>
        <div onClick={disableAutoplay} className={classes.closeButton}>
          <X />
        </div>
        <div className={classes.countdown}>
          <Countdown date={countDownTo} onComplete={countdownCompleteCallback} />
        </div>
        <iframe
          src={beforeVideo}
          title="Countdown"
          frameBorder="0"
          style={{ width: '100%', height: '100%' }}
          allow="autoplay; fullscreen; encrypted-media"
        />
      </div>
    );
  };

  return playVideo ? is2021 ? newYearVideo() : countdownVideo() : <></>;
};

export default CountdownVideo;
