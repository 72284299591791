import React, { useEffect, useRef, useState } from 'react';
import classes from './AudioPlayer.module.scss';
import { Scrubber } from 'react-scrubber';
import 'react-scrubber/lib/scrubber.css';
import { CircularProgress } from '@material-ui/core';
import { Pause, PlayArrow } from '@material-ui/icons';
import { getBrowser } from 'utils/BrowserDetection';

interface AudioPlayerProps {
  url: string;
  autoPlay?: boolean;
  bar?: boolean;
  showTime?: boolean;
}

const AudioPlayer: React.FunctionComponent<AudioPlayerProps> = ({
  url,
  autoPlay = false,
  bar = true,
  showTime = true,
}) => {
  const audioRef = useRef<HTMLAudioElement>(null);

  const [playing, setPlaying] = useState(false);
  const [loading, setLoading] = useState(true);
  const [scrubbing, setScrubbing] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [playTime, setPlayTime] = useState(0);

  useEffect(() => {
    const setPlayedTime = e => {
      if (scrubbing) return;
      setCurrentTime(e.target.currentTime);
    };
    const setPlayingTrue = e => setPlaying(true);
    const setPlayingFalse = e => setPlaying(false);
    const setLoadedFalse = e => setLoading(false);

    const audioElem = audioRef.current;
    audioElem.addEventListener('loadeddata', () => setPlayTime(audioElem.duration));
    audioElem.addEventListener('timeupdate', setPlayedTime);
    audioElem.addEventListener('playing', setPlayingTrue);
    audioElem.addEventListener('pause', setPlayingFalse);
    audioElem.addEventListener('canplay', setLoadedFalse);
    return () => {
      audioElem.removeEventListener('loadeddata', () => setPlayTime(audioElem.duration));
      audioElem.removeEventListener('timeupdate', setPlayedTime);
      audioElem.removeEventListener('playing', setPlayingTrue);
      audioElem.removeEventListener('pause', setPlayingFalse);
      audioElem.removeEventListener('canplay', setLoadedFalse);
    };
  }, [audioRef, scrubbing]);

  const playpause = () => {
    if (playing) audioRef.current.pause();
    else audioRef.current.play();
  };

  const onScrubChange = (value: number) => {
    setCurrentTime(value);
  };

  const onScrubStart = () => {
    setScrubbing(true);
  };

  const onScrubEnd = (value: number) => {
    setCurrentTime(value);
    audioRef.current.currentTime = value;
    setLoading(true && getBrowser() !== 'safari');
    setScrubbing(false);
  };

  const getButtonicon = (loadingTest = false) => {
    // return <CircularProgress className={classes.playerSpinner} />;
    if (!loading && !loadingTest)
      return playing ? <Pause className={classes.playerIcon} /> : <PlayArrow className={classes.playerIcon} />;

    return <CircularProgress size="1cm" />; //This hardcode is not good. Only way I could get the play/pause button to be the same size as the spinner.
  };

  const elapsedTime = new Date(currentTime * 1000).toISOString().substr(14, 5);
  const goalTime = new Date(playTime * 1000).toISOString().substr(14, 5);

  if (getBrowser() === 'safari') {
    const urlParam = url.split('?');
    url = urlParam[0].replace('www.', 'dl.');
  }

  return (
    <div className={classes.AudioPlayer}>
      <audio ref={audioRef} src={url} autoPlay={autoPlay} />
      <div className={classes.FlexedWrapper}>
        <Scrubber
          className={classes.scrubber}
          min={0}
          max={playTime}
          value={currentTime}
          onScrubStart={onScrubStart}
          onScrubEnd={onScrubEnd}
          onScrubChange={onScrubChange}
          hidden={!bar}
        />
        <div className={classes.controlbar}>
          <button className={classes.playPause} onClick={playpause} disabled={loading}>
            {getButtonicon()}
          </button>
          <div hidden={!showTime}>{elapsedTime + '/' + goalTime}</div>
        </div>
      </div>
    </div>
  );
};

export default AudioPlayer;
