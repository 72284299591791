import React from 'react';
import Player from 'components/player/Player';
import * as Synchronicity from 'synchronicity-types';

const Video: React.FunctionComponent<Synchronicity.VideoRoom> = ({
  title,
  url,
  width,
  height,
  autoplay,
  description,
}) => {
  return <Player title={title} url={url} width={width} height={height} autoplay={autoplay} description={description} />;
};

export default Video;
