import React from 'react';
import classes from './Modal.module.scss';
import { ReactComponent as Arrow } from '../../assets/icons/left-arrow.svg';
import { ReactComponent as Heart } from '../../assets/icons/heart-small.svg';

interface ModalProps {
  onClick: () => void;
  type?: string;
}

const Modal: React.FunctionComponent<ModalProps> = ({ onClick, type, children }) => {
  const classnames =
    type === 'podcast' ? classes.backdropPod : type === 'pride' ? classes.prideModal : classes.backdrop;

  return (
    <div>
      <div className={classes.navigationBar} onClick={onClick}>
        <Arrow /> CONTINUE EXPLORING <Heart />
      </div>
      <div className={classnames}>{children}</div>
    </div>
  );
};

export default Modal;
