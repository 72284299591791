import React from 'react';
import classes from './House.module.scss';
import { convexHull, createPath, getPathPoints } from './House.utils';

interface HouseProps {
  id: number;
  isInner?: boolean;
  d: string;
  fill?: string;
  transform?: string;
  className?: string;
  fillRule?: 'nonzero' | 'evenodd' | 'inherit' | undefined;
  hasDoor?: boolean;
  doorTransform?: string;
  onClick?: (houseId: number) => void;
  onMouseEnter?: (houseId: number) => void;
  onMouseLeave?: () => void;
}

const House: React.FunctionComponent<HouseProps> = ({
  id = '-1',
  d,
  fill = '#221F3F',
  transform = '',
  isInner = false,
  className = '',
  fillRule = 'nonzero',
  doorTransform = '',
  hasDoor = doorTransform !== '' ? true : false,
  onClick,
  onMouseEnter,
  onMouseLeave,
}) => {
  const classList: string[] = className !== '' ? className.split(' ') : [className];
  const stylingClasses = classList.map(c => classes[c]).join(' ');

  const convexHullPath = React.useMemo(() => {
    const points = getPathPoints(d);
    const hull = convexHull(points);
    const path = createPath(hull);

    return path;
  }, [d]);

  return (
    <g
      onClick={() => {
        typeof onClick === 'function' && onClick(+id);
      }}
      transform={transform}
      onMouseEnter={() => {
        typeof onMouseEnter === 'function' && onMouseEnter(+id);
      }}
      onMouseLeave={() => {
        typeof onMouseLeave === 'function' && onMouseLeave();
      }}
      className={stylingClasses}
      fill={fill}
    >
      <path id={id.toString()} d={d} fillRule={fillRule} className={classes.housePath}></path>

      <path d={convexHullPath} fill="transparent" />
    </g>
  );
};

export default House;
