import React from 'react';
import classes from './Player.module.scss';

interface PlayerProps {
  title: string;
  url: string;
  width: string;
  height: string;
  autoplay: boolean;
  description: string;
}

const Player: React.FunctionComponent<PlayerProps> = ({ title, url, width, height, autoplay, description }) => {
  const autoplayUrl = autoplay ? url + '?autoplay=1' : url;
  return (
    <iframe
      className={classes.iframe}
      title={title}
      src={autoplayUrl}
      style={{ height, width }}
      frameBorder="0"
      allow="autoplay; fullscreen; encrypted-media"
      allowFullScreen
    />
  );
};

export default Player;
