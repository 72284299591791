/* eslint-disable no-undef */
export const isOpera = () => {
  return (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;
};

export const isFirefox = () => {
  return typeof InstallTrigger !== 'undefined';
};

export const isSafari = () => {
  return (
    /constructor/i.test(window.HTMLElement) ||
    (function(p) {
      return p.toString() === '[object SafariRemoteNotification]';
    })(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification))
  );
};

export const isIE = () => {
  return /*@cc_on!@*/ false || !!document.documentMode;
};

export const isEdge = () => {
  return !isIE() && !!window.StyleMedia;
};

export const isChrome = () => {
  return !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
};

export const getBrowser = () => {
  if (isChrome()) return 'chrome';
  else if (isEdge()) return 'edge';
  else if (isIE()) return 'ie';
  else if (isSafari()) return 'safari';
  else if (isFirefox()) return 'firefox';
  else if (isOpera()) return 'opera';
};
