import React from 'react';
import * as Synchronicity from 'synchronicity-types';
import 'react-scrubber/lib/scrubber.css';
import AudioPlayer from 'components/audioPlayer/AudioPlayer';
import classes from '../page/Page.module.scss';

const Audio: React.FunctionComponent<Synchronicity.AudioRoom> = ({ title, description, url, autoPlay = false }) => {
  return (
    <div className={classes.AudioContent}>
      <h3 hidden={title === undefined}>{title}</h3>
      <p hidden={description === undefined}>{description}</p>
      <AudioPlayer url={url} autoPlay={autoPlay} />
    </div>
  );
};

export default Audio;
